import { useCallback, useEffect, useState } from 'react';
import { FINANCE_SCOPE } from './constants';
import { FilterType } from '@xyla/ask-oe';

interface UserMetadata {
  isLoggedIn: boolean;
  hasAskoeAccess: boolean;
  hasSetUserData: boolean;
  defaultFilter: FilterType;
}

const DEFAULT_METADATA: UserMetadata = {
  isLoggedIn: false,
  hasAskoeAccess: false,
  hasSetUserData: false,
  defaultFilter: 'finance',
};

export function useUserMetadata() {
  const [userMetadata, setUserMetadata] = useState(DEFAULT_METADATA);
  const [isLoading, setIsLoading] = useState(true);

  const fetchMetadata = useCallback(() => {
    fetch('/api/fetchuserinfo')
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then((responseJson) => {
        const hasPocAccess =
          responseJson.permissions?.includes(FINANCE_SCOPE) ?? false;
        setUserMetadata({
          isLoggedIn: Boolean(responseJson.accessTokenScope),
          hasAskoeAccess: hasPocAccess,
          hasSetUserData: hasPocAccess,
          defaultFilter: responseJson.filter_preference,
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [setUserMetadata]);

  useEffect(() => {
    fetchMetadata();
  }, [fetchMetadata]);

  return {
    isLoading,
    ...userMetadata,
  };
}
