export default function WhiteBisonIcon() {
  return (
    <svg
      width='49'
      height='49'
      viewBox='0 0 49 49'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Mammoth emoji 1'>
        <g id='color'>
          <path
            id='Vector'
            d='M41.2466 21.6368C41.1808 22.4732 40.9252 23.2836 40.4991 24.0063C40.1111 24.7009 39.882 25.4729 39.8283 26.2667C39.7746 27.0604 39.8977 27.8562 40.1887 28.5967C40.4889 29.137 40.6525 29.7426 40.665 30.3607C40.6775 30.9787 40.5386 31.5904 40.2605 32.1424V37.0901C40.2601 37.5412 40.0807 37.9738 39.7617 38.2929C39.4428 38.612 39.0102 38.7916 38.5591 38.7921H36.4383C36.2623 38.7921 36.0932 38.7239 35.9664 38.6018C35.8396 38.4798 35.7651 38.3134 35.7584 38.1375C35.7511 37.9547 35.7132 36.3211 36.5552 35.4458C36.7298 35.2623 36.9417 35.1184 37.1767 35.0239V32.5576C36.4057 32.0026 34.4624 30.441 33.845 28.4084C32.5881 29.2633 31.1075 29.7296 29.5876 29.7491C26.4838 29.7491 23.8687 27.0396 23.848 26.8834C23.6234 25.1796 22.9633 24.6343 23.0722 24.2748C24.2249 20.4712 27.7164 16.3408 29.5535 14.353L31.2331 14.5618C33.0746 15.0608 37.4302 16.4423 40.5756 19.0454C40.5756 19.0454 41.2466 19.7655 41.2466 21.6368Z'
            fill='white'
          />
          <path
            id='Vector_2'
            d='M17.3002 26.5329C17.1521 26.4301 17.0081 26.3329 16.8747 26.2467L16.8709 26.2429C16.5759 26.0508 16.2883 25.8754 16.022 25.7237V30.8891C16.022 31.0237 15.9821 31.1552 15.9073 31.2672C15.8325 31.3791 15.7262 31.4663 15.6019 31.5178C15.4775 31.5693 15.3407 31.5827 15.2087 31.5565C15.0767 31.5302 14.9554 31.4654 14.8602 31.3702C12.6053 29.1152 10.8062 28.5125 9.69358 28.4041C9.07703 28.3511 8.48812 28.1245 7.99514 27.7504C7.50216 27.3764 7.1253 26.8702 6.90827 26.2907L5.09922 21.4098C4.87085 20.7967 4.79878 20.1364 4.88951 19.4885C4.98024 18.8406 5.23091 18.2255 5.61889 17.6987L7.30966 15.3963C7.5742 15.0372 7.91926 14.7452 8.31712 14.5437C8.71498 14.3422 9.15456 14.2368 9.60055 14.2359L12.9897 14.2339C15.3704 11.2817 21.1303 6.93331 31.8132 12.4402C31.9084 12.4892 31.9906 12.56 32.0533 12.6467C32.116 12.7335 32.1573 12.8339 32.1739 12.9397C32.1905 13.0454 32.1819 13.1536 32.1488 13.2554C32.1156 13.3572 32.0589 13.4498 31.9833 13.5255C31.9308 13.5787 26.454 19.3846 24.4563 24.4635C25.1363 26.1775 25.8787 29.1696 24.2078 31.6672C22.7862 33.7913 22.7582 38.1318 22.7582 38.1751C22.7579 38.3553 22.686 38.5281 22.5584 38.6554C22.4308 38.7827 22.2579 38.8542 22.0777 38.8542H16.2364C16.056 38.8542 15.8829 38.7825 15.7552 38.6549C15.6276 38.5273 15.5559 38.3542 15.5559 38.1737C15.5559 36.4504 15.9593 35.2069 16.7542 34.4772C17.0587 34.1932 17.4315 33.9929 17.8363 33.8956L18.7535 30.8969C17.7644 29.6628 17.2502 28.1152 17.3039 26.5345L17.301 26.5326'
            fill='white'
          />
          <path
            id='Vector_3'
            d='M31.0137 38.7917H33.8809C34.045 38.7916 34.2035 38.7323 34.3273 38.6246C34.4511 38.5169 34.5319 38.368 34.5546 38.2055C34.7248 36.9879 35.1827 35.0545 35.6672 34.5701C36.6568 33.5805 37.0589 31.4172 37.1014 31.1733C37.13 31.0659 37.1274 30.9526 37.0939 30.8467C37.0603 30.7407 36.9972 30.6465 36.912 30.5752C36.3448 30.1776 35.873 29.659 35.5307 29.0567C35.1885 28.4545 34.9844 27.7837 34.9331 27.0929C34.8915 26.9575 34.8086 26.8385 34.696 26.7524C34.5834 26.6664 34.4468 26.6176 34.3052 26.6129C34.1636 26.6082 34.024 26.6479 33.906 26.7263C33.788 26.8047 33.6974 26.918 33.6469 27.0504C33.0007 27.4542 32.3106 27.7829 31.59 28.0304C31.4598 28.0788 31.3476 28.1659 31.2683 28.2799C31.1891 28.394 31.1466 28.5295 31.1467 28.6684C31.1467 30.3279 32.4905 31.9024 33.1558 32.5802L32.1629 34.927C31.7144 35.0066 31.3057 35.2346 31.0025 35.5744C30.4574 36.3303 30.2199 37.2651 30.3378 38.1895C30.357 38.3554 30.4364 38.5083 30.5611 38.6194C30.6857 38.7304 30.8468 38.7917 31.0137 38.7917Z'
            fill='white'
          />
          <path
            id='Vector_4'
            d='M5.61884 17.6983L7.30961 15.396C7.57415 15.037 7.91921 14.7449 8.31707 14.5434C8.71494 14.3419 9.15451 14.2365 9.6005 14.2357L13.3203 14.2336C13.3297 14.2336 13.3383 14.2387 13.3477 14.2391C13.5242 14.2583 13.6894 14.3348 13.8182 14.4569C13.8241 14.4632 13.8324 14.4657 13.8381 14.4723C14.2941 15.0641 14.6619 15.7189 14.9299 16.4163C15.4493 16.1746 15.8606 15.749 16.0846 15.2217C16.1439 15.0911 16.2423 14.9823 16.3663 14.9104C16.4903 14.8384 16.6337 14.8069 16.7764 14.8201C16.9194 14.8349 17.054 14.8946 17.161 14.9906C17.2679 15.0865 17.3418 15.2139 17.3719 15.3544C17.4066 15.5144 18.1081 18.9674 15.9557 20.6307C16.0969 21.9525 16.1191 23.2844 16.022 24.6103V30.8886C16.0219 31.0232 15.982 31.1548 15.9072 31.2667C15.8324 31.3786 15.7262 31.4658 15.6018 31.5173C15.4775 31.5688 15.3406 31.5823 15.2086 31.556C15.0766 31.5298 14.9554 31.4649 14.8602 31.3698C12.6052 29.1148 10.8061 28.512 9.69353 28.4036C9.07698 28.3507 8.48807 28.124 7.99509 27.7499C7.50211 27.3759 7.12526 26.8698 6.90822 26.2902L5.09917 21.4094C4.87082 20.7963 4.79876 20.136 4.88948 19.4881C4.98021 18.8402 5.23087 18.2251 5.61884 17.6983Z'
            fill='white'
          />
        </g>
        <g id='line'>
          <path
            id='Vector_5'
            d='M40.3364 22.7202C40.2216 23.0501 40.0772 23.369 39.905 23.673C39.4658 24.4658 39.2087 25.3465 39.1524 26.2512C39.0961 27.1558 39.242 28.0616 39.5795 28.9027C39.8428 29.3683 39.9812 29.894 39.9812 30.4289C39.9812 30.9637 39.8428 31.4895 39.5795 31.955V37.0902C39.5794 37.361 39.4718 37.6207 39.2803 37.8121C39.0888 38.0035 38.8291 38.1111 38.5583 38.1111H36.4377C36.4377 38.1111 36.3399 35.5759 37.8568 35.5759V32.1996C37.8568 32.1996 34.3336 29.9487 34.3336 27.1596C33.0346 28.3492 31.3481 29.0272 29.5872 29.068C28.5643 29.0384 27.5656 28.7508 26.6837 28.2319'
            stroke='white'
            strokeWidth='1.36111'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Vector_6'
            d='M35.7861 33.1636C35.6401 33.504 35.437 33.8171 35.1855 34.0891C34.3198 34.9549 33.8798 38.1112 33.8798 38.1112H31.0127C31.0127 38.1112 30.7168 35.5651 32.6313 35.5651L33.9642 32.4141C33.9642 32.4141 31.8262 30.5434 31.8262 28.6687'
            stroke='white'
            strokeWidth='1.36111'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Vector_7'
            d='M30.2182 15.0083C30.2182 15.0083 45.9263 18.4721 43.1337 27.9236'
            stroke='white'
            strokeWidth='1.36111'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Vector_8'
            d='M15.341 24.6053V30.889C12.9631 28.511 11.0141 27.849 9.75971 27.7266C9.27068 27.6846 8.80346 27.5054 8.41184 27.2095C8.02022 26.9136 7.72013 26.5132 7.54613 26.0542L5.73666 21.1729C5.54851 20.6654 5.48934 20.119 5.56445 19.5829C5.63957 19.0468 5.84663 18.5377 6.16705 18.1014L7.85782 15.7991C8.05873 15.5255 8.3212 15.3031 8.62401 15.1498C8.92682 14.9965 9.26146 14.9166 9.60086 14.9166L13.3209 14.9148C15.6592 11.8325 21.1388 7.70447 31.4917 13.0404'
            stroke='white'
            strokeWidth='1.36111'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Vector_9'
            d='M23.0484 23.1245C23.3059 23.5565 23.5317 24.0065 23.724 24.4711C24.4182 26.1149 25.1976 28.9628 23.6417 31.2882C22.0859 33.6136 22.0771 38.1739 22.0771 38.1739H18.3569C18.3541 35.6206 19.9304 35.5323 19.9304 35.5323L21.2035 30.8887C21.2035 28.6597 18.9561 26.782 17.2444 25.6751'
            stroke='white'
            strokeWidth='1.36111'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Vector_10'
            d='M18.3981 38.1739H16.2354C16.2354 34.336 18.356 34.5182 18.356 34.5182L19.2266 30.6492'
            stroke='white'
            strokeWidth='1.36111'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <path
          id='Vector 106'
          d='M13.4739 19.345C13.2353 18.4161 13.4724 18.0314 13.6115 17.7411C13.843 17.3158 14.8531 17.8481 15.8644 17.3657C17.2724 16.3331 16.2399 14.7373 16.6153 14.268C16.7092 14.1506 18.0234 16.3331 17.2724 18.0227C16.8784 19.0516 15.6766 19.9939 14.4563 20.2756C14.1671 20.3423 13.6528 20.0417 13.4739 19.345Z'
          fill='white'
          stroke='white'
          strokeWidth='0.18774'
        />
      </g>
    </svg>
  );
}
