import { SxProps } from '@mui/material';
import { Box } from '@mui/system';
import { ReactNode } from 'react';

export function LoggedOutWrapper({
  children,
  sx,
}: {
  children: ReactNode;
  sx?: SxProps;
}) {
  return (
    <Box
      color='primary.main'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '67vh',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
