// copied from openevidence src/components/ApiPage/ContactDialog.tsx
import { ReactNode } from 'react';
import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ContactDialogProps {
  dialogProps: DialogProps;
  children?: ReactNode;
}

/** Displays a simple dialog for the contact form */
export function ContactDialog({ dialogProps, children }: ContactDialogProps) {
  return (
    <Dialog
      {...dialogProps}
      sx={{ '.MuiPaper-root': { m: { xs: '10px', sm: '32px' } } }}
    >
      <DialogTitle>Apply for Access</DialogTitle>
      <IconButton
        aria-label='close'
        sx={{ position: 'absolute', right: '12px', top: '12px' }}
        // onClose expects params, but we don't use any, so this is fine
        onClick={dialogProps.onClose as any}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers sx={{ pt: 2 }}>
        {children}
      </DialogContent>
    </Dialog>
  );
}
