// copied from openevidence src/components/ApiPage/ContactForm.tsx
import { useState } from 'react';
import { Box, FormControl, FormLabel, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import {
  ContactSalesSubmittedEventProps,
  useExternalTracking,
} from '@xyla/analytics';
import { SubmitState } from '@/pages/landing';

function getInputValidators(maxLength: number) {
  return {
    required: 'This field is required',
    maxLength: {
      value: maxLength,
      message: `Input must be ${maxLength} characters or less`,
    },
  };
}

interface ContactFormProps {
  setSubmitState: (value: SubmitState | null) => void;
}

export function ContactForm({ setSubmitState }: ContactFormProps) {
  const { trackMixpanelEvent } = useExternalTracking();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  const submitData: SubmitHandler<FieldValues> = async (data) => {
    setSubmitState(null);
    setLoading(true);
    const response = await trackMixpanelEvent('contact_sales_submitted', {
      user_name: data.name,
      user_email: data.email,
      user_org: data.org,
      user_description: data.description,
    } satisfies ContactSalesSubmittedEventProps);

    setLoading(false);
    if (response?.ok) {
      setSubmitState({
        status: 'success',
        message: 'Application submitted.',
      });
    } else {
      console.error('Error submitting application', response);
      setSubmitState({
        status: 'error',
        message:
          'There was an error submitting your application. Please try again in a few moments.',
      });
    }
  };

  return (
    <Box
      component='form'
      onSubmit={handleSubmit(submitData)}
      noValidate // Turn off native HTML validation so we can do it in JS
      sx={{ maxWidth: '450px' }}
    >
      <TextField
        id='contact-name'
        label='Name'
        required
        fullWidth
        margin='dense'
        autoFocus
        {...register('name', getInputValidators(100))}
        error={errors.name !== undefined}
        helperText={errors.name?.message as string | undefined}
      />
      <TextField
        id='contact-email'
        label='Business email'
        required
        fullWidth
        margin='dense'
        {...register('email', getInputValidators(100))}
        error={errors.email !== undefined}
        helperText={errors.email?.message as string | undefined}
      />
      <TextField
        id='contact-org'
        label='Organization name'
        required
        fullWidth
        margin='dense'
        {...register('org', getInputValidators(100))}
        error={errors.org !== undefined}
        helperText={errors.org?.message as string | undefined}
      />
      <FormControl sx={{ width: '100%' }}>
        <FormLabel htmlFor='contact-description' sx={{ mt: '12px', mb: '4px' }}>
          Why are you interested in Bison? *
        </FormLabel>
        <TextField
          id='contact-description'
          required
          fullWidth
          margin='dense'
          multiline
          hiddenLabel
          minRows={3}
          maxRows={6}
          {...register('description', getInputValidators(1000))}
          error={errors.description !== undefined}
          helperText={errors.description?.message as string | undefined}
        />
      </FormControl>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
        <LoadingButton
          onClick={() => console.log(errors)}
          loading={loading}
          variant='contained'
          type='submit'
          sx={{ borderRadius: '3px !important' }} // match radius with buttons on API Page
        >
          Submit
        </LoadingButton>
      </Box>
    </Box>
  );
}
