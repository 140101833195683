import TypeIt from 'typeit-react';
import { Button, Container } from '@mui/material';
import styles from './index.module.css';
export { ContactForm } from './ContactForm';
export { ContactDialog } from './ContactDialog';
import { useRouter } from 'next/router';
import WhiteBisonIcon from '@/src/icons/WhiteBisonIcon';

interface BisonLandingProps {
  onContactClick: () => void;
}

export function BisonLanding({ onContactClick }: BisonLandingProps) {
  const router = useRouter();

  const question1 = `Which companies have GLP-1 drugs on market or in development?`;
  const answer1 = `Several companies have GLP-1 drugs on the market or in development. Here is a summary of the key players and their respective drugs:
  • Novo Nordisk: They are the market leader with several GLP-1 drugs including Ozempic, Wegovy, Rybelsus, Victoza, and Saxenda.[1-4]
  • Eli Lilly: They have Trulicity and Mounjaro (tirzepatide) on the market, with other GLP-1 drugs like Dulaglutide in development.[1][3][5-7]
  • Pfizer: They are developing an oral GLP-1 agonist, danuglipron, which is in Phase 2 trials, and another undisclosed GLP-1 in Phase 1.[5][8]
  • AstraZeneca: They market Exenatide (Bydureon) and Lixisenatide (Adlyxin).[3][9]
  • Sanofi: They market Lixisenatide (Adlyxin).[9]
  • Amgen, Bristol Myers Squibb, and Novartis: These companies have early-stage GLP-1 assets in development.[2]
  • Viking Therapeutics: They are developing a dual GLP-1 and GIP agonist.[2]
  • Jiangsu Hansoh Pharmaceutical, Boehringer Ingelheim, Altimmune, Carmot Therapeutics, and Sciwind Biosciences: These companies are developing GLP-1R plus dual/tri incretin targeting peptides.[9]
  • Qilu Regor Therapeutics Inc.: They have GLP-1R peptides for diabetes and obesity.[9]
  • Structure Therapeutics Inc.: They are developing GLP-1R small molecules.[9]
  These companies are actively contributing to the competitive and rapidly evolving GLP-1 drug market`;

  const question2 = `Who are Nvidia suppliers?`;
  const answer2 = `
  Nvidia relies on several key suppliers for its semiconductor manufacturing, memory components, and testing equipment.
  • TSMC (Taiwan Semiconductor Manufacturing Company): TSMC is Nvidia's primary foundry partner, manufacturing its advanced AI chips.[1-3]
  • ASML: ASML supplies the lithography machines that TSMC uses to produce Nvidia's chips.[1]
  • SK Hynix and Samsung Electronics: Both companies supply high-bandwidth memory (HBM) chips used in Nvidia's AI processors.[2][4]
  • Micron Technology: Micron also provides memory components, although it holds a smaller market share compared to SK Hynix and Samsung.[2][4]
  • BESI (BE Semiconductor Industries): BESI supplies semiconductor equipment used in the assembly and packaging of Nvidia's chips.[2]
  • Advantest: This Japanese firm provides testing equipment for Nvidia's AI chips.[2]`;

  return (
    <div className={styles.outerContainer}>
      <div className={styles.container}>
        <div className={styles.box}>
          <WhiteBisonIcon />

          <h1 className={styles.title}>
            Bison <span>is Research Intelligence</span>
          </h1>

          <Button className={styles.button} onClick={onContactClick}>
            Apply here for access
          </Button>
          <Button
            className={styles.loginButton}
            onClick={() => {
              router.push('/api/auth/login');
            }}
          >
            Login
          </Button>
        </div>
        <div className={styles.backgroundContainer}>
          <div className={styles.background}>
            <TypeIt
              options={{
                speed: 0.1,
                waitUntilVisible: true,
                loop: true,
              }}
              getBeforeInit={(instance) => {
                instance
                  .type(question1)
                  .break()
                  .break()
                  .type(answer1)
                  .pause(2000)
                  .empty()

                  .type(question2)
                  .break()
                  .break()
                  .type(answer2)
                  .pause(2000)
                  .empty();

                return instance;
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
