import { BisonLanding } from '@/src/components/Finance/BisonLanding';

import { useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import {
  ContactForm,
  ContactDialog,
} from '@/src/components/Finance/BisonLanding';
import { useRouter } from 'next/router';
import { useUserMetadata } from '@/src/components/Ask/useUserMetadata';
import { CircularProgress, Typography } from '@mui/material';
import { LoggedOutWrapper } from '@/src/components/Ask/loggedOutWrapper';

/** Form submission results for user messaging */
export type SubmitState = {
  status: 'success' | 'error';
  message: string;
};

export default function Landing() {
  const router = useRouter();
  const userMetadata = useUserMetadata();

  const [submitState, setSubmitState] = useState<SubmitState | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  if (userMetadata.isLoading || userMetadata.isLoggedIn) {
    if (userMetadata.isLoggedIn) {
      router.push('/ask');
    }

    return (
      <LoggedOutWrapper>
        <CircularProgress />
        <Typography color='primary.contrastText' mt={4}>
          Verifying login
        </Typography>
      </LoggedOutWrapper>
    );
  }

  const resetSubmitState = () => {
    setSubmitState(null);
  };
  const handleSubmitStateChange = (value: SubmitState | null) => {
    if (value?.status === 'success') {
      setDialogOpen(false);
    }
    setSubmitState(value);
  };

  return (
    <>
      {/* ContactForm within a Dialog. Extract the form to a standalone page later. */}
      {
        <ContactDialog
          dialogProps={{
            open: dialogOpen,
            onClose: () => setDialogOpen(false),
          }}
        >
          <ContactForm setSubmitState={handleSubmitStateChange} />
        </ContactDialog>
      }

      {/* User messaging on submit success/error */}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ mb: '60px' }}
        open={Boolean(submitState)}
        onClose={resetSubmitState}
        autoHideDuration={5000}
      >
        {submitState ? (
          <Alert
            variant='standard'
            severity={submitState.status}
            sx={{ boxShadow: 3, borderRadius: 1 }}
          >
            {submitState.message}
          </Alert>
        ) : undefined}
      </Snackbar>
      <BisonLanding onContactClick={() => setDialogOpen(true)}></BisonLanding>
    </>
  );
}
